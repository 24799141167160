export default (formState, additionalParams = {}) => {
  let params = {
    personal_data: {},
    family_data: {
      family_member: []
    },
    marriage_data: {
      family_core_member: []
    },
    health_history_data: {},
    skill_data: {
      list: []
    },
    education_history_data: {
      list: []
    },
    job_history_data: {
      list: []
    },
    church_member_data: {},
    financial_data: {},
    personal_spiritual_data: {},
    personal_struggle_data: {},
    other_data: {},
    agreement_data: {},
    ...additionalParams
  }
  Object.keys(formState).map(item => {
    if (typeof formState[item] !== 'string') {
      formState[item].map(itm => {
        let value = itm.value
        if (itm.type === 'date' || itm.type === 'year') {
          value = Date.parse(new Date(itm.value))/1000 ? Date.parse(new Date(itm.value))/1000 : 0
        }
        if (itm.type === 'select') {
          value = itm.value === 'Ya' ? true : itm.value === 'Tidak' ? false : itm.value
        }
        if (itm.type === 'file') {
          value = itm.path
        }
        if (itm.type === 'number' && itm.subfields === 'ipk') {
          value = parseFloat(itm.value)
        }
        if (itm.subfields) {
          if (params[itm.parent][itm.fields].length === 0) {
            params[itm.parent][itm.fields] = []
            params[itm.parent][itm.fields].push({
              [itm.subfields]: value,
              attr: itm.attr
            })
          } else {
            const idx = params[itm.parent][itm.fields].findIndex(obj => obj.attr === itm.attr)
            if (idx !== -1) {
              params[itm.parent][itm.fields].map(obj => {
                if (obj.attr === itm.attr) {
                  obj[itm.subfields] = value
                }
              })
            }
            else {
              params[itm.parent][itm.fields].push({
                [itm.subfields]: value,
                attr: itm.attr
              })
            }
          }
        } else {
          params[itm.parent][itm.fields] = value
        }
      })
    }
  })
  return params
}