<template>
  <div class="mt-4">
    <v-alert
      v-if="!isDisabledSave && !isHideButton"
      outlined
      type="warning"
      prominent
      border="left"
    >
      Silakan pilih SIMPAN jika Anda belum yakin dengan data yang Anda masukan. Apabila semua data yang diperlukan sudah sesuai, silakan pilih KIRIM.
    </v-alert>
    <v-alert
      dismissible
      type="error"
      v-model="isShowAlert"
    >{{ alertMsg }}</v-alert>
    <validation-observer ref="form">
      <v-form id="form-register-mdiv" @submit.prevent="submitForm">
        <div>
          <p class="title-14 mb-2">Konsentrasi yang dipilih</p>
          <v-select
            v-model="concentration"
            :items="concentrationList"
            :disabled="isHideButton"
            dense
            outlined
          ></v-select>
        </div>
        <v-stepper
          v-model="activeStep"
          elevation="0"
          vertical
          class="stepper-form-mdiv"
        >
          <v-stepper-step
            :step="1"
            @click="handleStep(1)"
          >
            <p class="stepper-form-mdiv-title">Data Pribadi</p>
          </v-stepper-step>

          <v-stepper-content :step="1">
            <v-row no-gutters class="general-form">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="general-form__col"
                v-for="(item, index) in getPersonalData"
                :key="index"
              >
                <template v-for="form in item.list">
                  <registration-input 
                    :key="form.title" 
                    :form="form"
                    @handleInput="handleInput"
                  />
                </template>
              </v-col>
            </v-row>
            <v-divider></v-divider>
            <p class="stepper-form-mdiv-title my-4">Dalam keadaan darurat, orang tidak serumah yang bisa dihubungi</p>
            <v-row no-gutters class="general-form" >
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="general-form__col"
                :key="form.title" 
                v-for="form in getContactData"
              >
                <registration-input :form="form" @handleInput="handleInput"/>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step
            :step="2"
            @click="handleStep(2)"
          >
            <p class="stepper-form-mdiv-title">Data Keluarga</p>
          </v-stepper-step>

          <v-stepper-content :step="2">
            <template v-for="(itm, index) in getFamilyData">
              <v-row no-gutters class="general-form" :key="`famData${index}`">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  class="general-form__col"
                  v-for="(item, idx) in separateColoum(itm.list)"
                  :key="`sprt${idx}`"
                >
                  <template
                    v-for="form in item.list"
                  >
                    <registration-input 
                      :key="form.title" 
                      :form="form"
                      @handleInput="handleInput"
                    />
                  </template>
                </v-col>
              </v-row>
              <v-divider :key="index" class="my-4"></v-divider>
            </template>
            <v-row no-gutters class="general-form">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="general-form__col"
                v-for="(item, idx) in separateColoum(getFamilyInfo)"
                :key="`fam${idx}`"
              >
                <template
                  v-for="form in item.list"
                >
                  <registration-input 
                    :key="form.title" 
                    :form="form"
                    @handleInput="handleInput"
                  />
                </template>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step
            :step="3"
            @click="handleStep(3)"
          >
            <p class="stepper-form-mdiv-title">Status Pernikahan</p>
          </v-stepper-step>

          <v-stepper-content :step="3">
            <template v-for="(itm, index) in getMarriedData">
              <p v-if="isMarried(itm.list, index)" :key="`mDataInfo${index}`" class="stepper-form-mdiv-body">Bila sudah menikah, isilah juga di bawah ini.</p>
              <v-row no-gutters v-if="isMarriedDataShow(itm.list, index) || itm.list[0].order === 1" class="general-form" :key="`marriedData${index}`">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  class="general-form__col"
                  v-for="(item, idx) in separateColoum(itm.list)"
                  :key="`mrd${idx}`"
                >
                  <template
                    v-for="form in item.list"
                  >
                    <registration-input 
                      :key="form.title" 
                      :form="form"
                      @handleInput="handleInput"
                    />
                  </template>
                </v-col>
              </v-row>
              <template v-if="isMarriedDataShow(itm.list, index) && itm.list[0].isMultiData && !isHideButton">
                <v-btn outlined :min-width="120" class="mr-2" :key="`mDataBtn1${index}`" @click="handleMultiForm('remove', itm.list[0])" :disabled="itm.list[0].isDisabledRemove">
                  HAPUS
                </v-btn>
                <v-btn color="primary" :min-width="120" :key="`mDataBtn2${index}`" @click="handleMultiForm('add', itm.list[0])">
                  TAMBAH
                </v-btn>
              </template>
              <v-divider :key="`mDataDiv${index}`" class="mb-4" v-if="isMarriedDataShow(itm.list, index)"></v-divider>
            </template>
            <v-row no-gutters v-if="isShowDivorced" class="general-form">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="general-form__col"
                v-for="(item, idx) in separateColoum(getDivorcedData)"
                :key="`dv${idx}`"
              >
                <template
                  v-for="form in item.list"
                >
                  <registration-input 
                    :key="form.title" 
                    :form="form"
                    @handleInput="handleInput"
                  />
                </template>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step
            :step="4"
            @click="handleStep(4)"
          >
            <p class="stepper-form-mdiv-title">Riwayat Kesehatan</p>
          </v-stepper-step>

          <v-stepper-content :step="4">
            <template
              v-for="form in getHealthHistory"
            >
              <registration-input 
                :key="form.title" 
                :form="form"
                @handleInput="handleInput"
              />
            </template>
          </v-stepper-content>
          <v-stepper-step
            :step="5"
            @click="handleStep(5)"
          >
            <p class="stepper-form-mdiv-title">Data Kompetensi Diri (beri tanda - atau angka 0 jika tidak ada)</p>
          </v-stepper-step>

          <v-stepper-content :step="5">
            <p class="stepper-form-mdiv-body mb-4">Misal: IT, seni, bahasa, olahraga, dll</p>
            <v-row no-gutters v-for="(itm, index) in getSkillData" :key="`skill${index}`" class="general-form">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="general-form__col"
                v-for="(item, idx) in separateColoum(itm.list)"
                :key="`sk${idx}`"
              >
                <template
                  v-for="form in item.list"
                >
                  <registration-input 
                    :key="form.title" 
                    :form="form"
                    @handleInput="handleInput"
                  />
                </template>
              </v-col>
              <template v-if="itm.list[0].isMultiData && !isHideButton">
                <v-btn outlined :min-width="120" class="mr-2" :key="`sDataBtn1${index}`" @click="handleMultiForm('remove', itm.list[0])" :disabled="itm.list[0].isDisabledRemove">
                  HAPUS
                </v-btn>
                <v-btn color="primary" :min-width="120" :key="`sDataBtn2${index}`" @click="handleMultiForm('add', itm.list[0])">
                  TAMBAH
                </v-btn>
              </template>
            </v-row>
          </v-stepper-content>
          <v-stepper-step
            :step="6"
            @click="handleStep(6)"
          >
            <p class="stepper-form-mdiv-title">Riwayat Pendidikan Tinggi Sebelumnya (Urutkan dari yang terbaru)</p>
          </v-stepper-step>

          <v-stepper-content :step="6">
            <template v-for="(itm, index) in getEducationData">
              <v-row no-gutters :key="`edu${index}`" class="general-form">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  class="general-form__col"
                  v-for="(item, idx) in separateColoum(itm.list)"
                  :key="`ed${idx}`"
                >
                  <template
                    v-for="form in item.list"
                  >
                    <registration-input 
                      :key="form.title" 
                      :form="form"
                      @handleInput="handleInput"
                    />
                  </template>
                </v-col>
                <template v-if="itm.list[0].isMultiData && !isHideButton">
                  <v-btn outlined :min-width="120" class="mr-2" :key="`eduDataBtn1${index}`" @click="handleMultiForm('remove', itm.list[0])" :disabled="itm.list[0].isDisabledRemove">
                    HAPUS
                  </v-btn>
                  <v-btn color="primary" :min-width="120" :key="`eduDataBtn2${index}`" @click="handleMultiForm('add', itm.list[0])">
                    TAMBAH
                  </v-btn>
                </template>
              </v-row>
            </template>
          </v-stepper-content>
          <v-stepper-step
            :step="7"
            @click="handleStep(7)"
          >
            <p class="stepper-form-mdiv-title">Riwayat Pekerjaan (Jika yang tidak pernah bekerja masukkan simbol tanda – atau angka 0)</p>
          </v-stepper-step>

          <v-stepper-content :step="7">
            <template v-for="(itm, index) in getJobData">
              <v-row no-gutters :key="`job${index}`" class="general-form">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  class="general-form__col"
                  v-for="(item, idx) in separateColoum(itm.list)"
                  :key="`jb${idx}`"
                >
                  <template
                    v-for="form in item.list"
                  >
                    <registration-input 
                      :key="form.title" 
                      :form="form"
                      @handleInput="handleInput"
                    />
                  </template>
                </v-col>
                <template v-if="itm.list[0].isMultiData && !isHideButton">
                  <v-btn outlined :min-width="120" class="mr-2" :key="`jDataBtn1${index}`" @click="handleMultiForm('remove', itm.list[0])" :disabled="itm.list[0].isDisabledRemove">
                    HAPUS
                  </v-btn>
                  <v-btn color="primary" :min-width="120" :key="`jDataBtn2${index}`" @click="handleMultiForm('add', itm.list[0])">
                    TAMBAH
                  </v-btn>
                </template>
              </v-row>
              <v-divider v-if="index !== getJobData.length - 1" :key="`jobdiv${index}`" class="mb-4" ></v-divider>
            </template>
          </v-stepper-content>
          <v-stepper-step
            :step="8"
            @click="handleStep(8)"
          >
            <p class="stepper-form-mdiv-title">Riwayat Pelayanan</p>
          </v-stepper-step>

          <v-stepper-content :step="8">
            <template v-for="(itm, index) in getServiceData">
              <v-row no-gutters :key="`service${index}`" class="general-form">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  class="general-form__col"
                  v-for="(item, idx) in separateColoum(itm.list)"
                  :key="`jb${idx}`"
                >
                  <template
                    v-for="form in item.list"
                  >
                    <registration-input 
                      :key="form.title" 
                      :form="form"
                      @handleInput="handleInput"
                    />
                  </template>
                </v-col>
                <template v-if="itm.list[0].isMultiData && !isHideButton">
                  <v-btn outlined :min-width="120" class="mr-2" :key="`jDataBtn1${index}`" @click="handleMultiForm('remove', itm.list[0])" :disabled="itm.list[0].isDisabledRemove">
                    HAPUS
                  </v-btn>
                  <v-btn color="primary" :min-width="120" :key="`jDataBtn2${index}`" @click="handleMultiForm('add', itm.list[0])">
                    TAMBAH
                  </v-btn>
                </template>
              </v-row>
              <v-divider v-if="index !== getServiceData.length - 1" :key="`svcdiv${index}`" class="mb-4" ></v-divider>
            </template>
          </v-stepper-content>
          <v-stepper-step
            :step="9"
            @click="handleStep(9)"
          >
            <p class="stepper-form-mdiv-title">Data Keanggotaan Gereja</p>
          </v-stepper-step>

          <v-stepper-content :step="9">
            <v-row no-gutters class="general-form">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="general-form__col"
                v-for="(item, idx) in separateColoum(getChurchData)"
                :key="`jb${idx}`"
              >
                <template
                  v-for="form in item.list"
                >
                  <registration-input 
                    :key="form.title" 
                    :form="form"
                    @handleInput="handleInput"
                  />
                </template>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step
            :step="10"
            @click="handleStep(10)"
          >
            <p class="stepper-form-mdiv-title">Data Finansial</p>
          </v-stepper-step>

          <v-stepper-content :step="10">
            <p class="stepper-form-mdiv-body mb-4">Studi Magister Divinitas (M.Div.) adalah studi penuh waktu yang tidak memungkinkan mahasiswa untuk melakukan pekerjaan lain di luar studi. Karena itu, Anda perlu mempersiapkan manajemen keuangan baik untuk diri sendiri, maupun keluarga (bila sudah menikah). Selama Anda studi, siapakah yang memberikan dukungan finansial untuk biaya studi?</p>
            <v-row no-gutters class="general-form">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="general-form__col"
                v-for="(item, idx) in separateColoum(getFinancialData)"
                :key="`fn${idx}`"
              >
                <template
                  v-for="form in item.list"
                >
                  <registration-input 
                    :key="form.title" 
                    :form="form"
                    @handleInput="handleInput"
                  />
                </template>
              </v-col>
            </v-row>
            <p class="stepper-form-mdiv-body">Silakan lengkapi Lembar Pernyataan Dukungan Finansial untuk Mahasiswa Program Studi Magister Divinitas (M.Div.)</p>
          </v-stepper-content>
          <v-stepper-step
            :step="11"
            @click="handleStep(11)"
          >
            <p class="stepper-form-mdiv-title">Formasi Spiritual Pribadi</p>
          </v-stepper-step>

          <v-stepper-content :step="11">
            <v-row no-gutters class="general-form">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="general-form__col"
                v-for="(item, idx) in separateColoum(getSpiritualData)"
                :key="`fn${idx}`"
              >
                <template
                  v-for="form in item.list"
                >
                  <registration-input 
                    :key="form.title" 
                    :form="form"
                    @handleInput="handleInput"
                  />
                </template>
              </v-col>
            </v-row>
          </v-stepper-content>
          <v-stepper-step
            :step="12"
            @click="handleStep(12)"
          >
            <p class="stepper-form-mdiv-title">Pergumulan Pribadi</p>
          </v-stepper-step>

          <v-stepper-content :step="12">
             <template
              v-for="form in getPergumulanData"
            >
              <registration-input 
                :key="form.title" 
                :form="form"
                @handleInput="handleInput"
              />
            </template>
          </v-stepper-content>
          <v-stepper-step
            :step="13"
            @click="handleStep(13)"
          >
            <p class="stepper-form-mdiv-title">Hal-hal Lain</p>
          </v-stepper-step>

          <v-stepper-content :step="13">
             <template v-for="(itm, index) in getOtherData">
              <v-row no-gutters :key="`other${index}`" class="general-form">
                <v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  class="general-form__col"
                  v-for="(item, idx) in separateColoum(itm.list)"
                  :key="`od${idx}`"
                >
                  <template
                    v-for="form in item.list"
                  >
                    <registration-input 
                      :key="form.title" 
                      :form="form"
                      @handleInput="handleInput"
                    />
                  </template>
                </v-col>
              </v-row>
              <v-divider v-if="index !== 1" :key="`odDiv${index}`" class="mb-4" ></v-divider>
            </template>
          </v-stepper-content>
          <v-stepper-step
            :step="14"
            @click="handleStep(14)"
          >
            <p class="stepper-form-mdiv-title">Persetujuan</p>
          </v-stepper-step>

          <v-stepper-content :step="14">
             <v-row no-gutters class="general-form">
              <v-col
                cols="12"
                sm="12"
                md="6"
                lg="6"
                class="general-form__col"
                v-for="(item, idx) in separateColoum(getAgreement)"
                :key="`ag${idx}`"
              >
                <template
                  v-for="form in item.list"
                >
                  <registration-input 
                    :key="form.title" 
                    :form="form"
                    @handleInput="handleInput"
                  />
                </template>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper>
        <div>
          <validation-provider
            v-slot="{ errors }"
            name="Persetujuan"
            rules="required"
          >
            <v-checkbox
              v-model="agreementCheckbox"
              :error-messages="errors"
              :disabled="isHideButton"
              :value="agreementCheckbox"
              label="Saya menyatakan bahwa semua keterangan di atas dibuat dengan sebenarnya dan sejujurnya. Apabila ada ketidakjujuran yang saya lakukan dalam pengisian formulir admisi, saya bersedia untuk didiskualifikasi dari proses admisi ini"
            ></v-checkbox>
          </validation-provider>
        </div>
        <div v-if="!isHideButton" class="d-flex justify-end">
          <v-btn 
            v-if="!isDisabledSave"
            color="primary"
            :min-width="120"
            outlined
            class="mr-2"
            @click="handleOnSubmit(true)"
            large
          >
            SIMPAN
          </v-btn>
          <v-btn 
            color="primary"
            :min-width="120"
            type="submit"
            form="form-register-mdiv"
            large
            :disabled="!agreementCheckbox"
          >
            KIRIM
          </v-btn>
        </div>
      </v-form>
    </validation-observer>
    <dialog-info ref="dialogInfoSuccess" :info="infoSuccess" :action="handleBack"/>
    <dialog-info ref="dialogInfoInvalid" :info="infoInvalid"/>
    <dialog-confirm ref="dialogConfirmSubmitForm" :info="info" :action="handleOnSubmit"/>
  </div>
</template>

<script>
// Libs
import { ValidationObserver, ValidationProvider, extend } from 'vee-validate'
import { get } from 'dot-prop'

// Utils
import loadscreen from '@/utils/common/loadScreen'
import request, { getConfig } from '@/utils/request'
import { checkHeardFrom } from '@/utils/common/checkHeardStta'
import processForm from '@/utils/common/processForm'
import { validateForm } from '@/utils/common/validateForm'

// Constants
import {
  API_FORM_REGISTRATION_FORM,
  API_DASH_REGISTRATION_FORM
} from '@/constants/apis'
import {
  INIT,
  ACCEPTED,
  REJECTED,
  ALUMNI,
  DROPOUT
} from '@/constants/status'
import {
  formValidateSthMdiv
} from '@/constants/formValidate'
import { required } from 'vee-validate/dist/rules'
import RegistrationInput from '../common/registrationInput'
import DialogConfirm from '@/components/common/DialogConfirm'
import DialogInfo from '@/components/common/DialogInfo'

  export default {
    data () {
      return {
        activeStep: 1,
        concentration: 'Penggembalaan',
        isShowDivorced: false,
        agreementCheckbox: false,
        info: {
          desc: 'Anda yakin data yang Anda masukan sudah benar?'
        },
        infoSuccess: {
          title: 'dialog.success',
          desc: 'dialog.success.form',
          btn: 'dialog.success.btn'
        },
        infoInvalid: {
          title: 'dialog.invalid',
          desc: 'dialog.invalid.form',
          btn: 'dialog.success.btn'
        },
        isShowAlert: false,
        alertMsg: ''
      }
    },
    components: {
      ValidationObserver,
      ValidationProvider,
      RegistrationInput,
      DialogConfirm,
      DialogInfo
    },
    props: {
      formId: {
        type: String,
        required: true
      }
    },
    computed: {
      isDashboard () {
        const config = getConfig()
        const path = this.$route.path
        return config.role === 'admin' && path.indexOf('dashboard') !== -1
      },
      isStudent () {
        const config = getConfig()
        return config.role === 'mahasiswa'
      },
      registrationStatus () {
        return get(this.$store.state, 'status', INIT)
      },
      isHideButton () {
        return (this.registrationStatus !== INIT && this.isStudent) || ([INIT, ACCEPTED, REJECTED, ALUMNI, DROPOUT].indexOf(this.registrationStatus) !== -1 && this.isDashboard)
      },
      isDisabledSave () {
        const isRegistrationForm = get(this.$store.state,'completionForm.is_registration_form', false)
        return !this.isStudent || isRegistrationForm
      },
      getPersonalData () {
        const personalData = get(this.$store.state, 'formRegist.personal_data', [])
        const median = 7
        return [{
          list: personalData.slice(0, median)
        }, {
          list: personalData.slice(median, 13)
        }]
      },
      getContactData () {
        const personalData = get(this.$store.state, 'formRegist.personal_data', [])
        return personalData.slice(13, personalData.length)
      },
      getFamilyData () {
        const famData = get(this.$store.state, 'formRegist.family_data', [])
        const median = 4
        return [{
          list: famData.slice(0, median)
        }, {
          list: famData.slice(median, 8)
        }]
      },
      getFamilyInfo () {
        const famData = get(this.$store.state, 'formRegist.family_data', [])
        return famData.slice(8, 10)
      },
      getMarriedData () {
        const marriedData = get(this.$store.state, 'formRegist.marriage_data', [])
        const data = []
        marriedData.map(itm => {
          if (itm.group !== 4) {
            const idx = itm.group > 4 ? 2 : 1
            if (!data[itm.group-idx]) {
              const list = []
              list.push(itm)
              data.push({list})
            } else {
              data[itm.group-idx].list.push(itm)
            }
          }
        })
        return data
      },
      getDivorcedData () {
        const divorcedData = get(this.$store.state, 'formRegist.marriage_data', [])
        const data = []
        divorcedData.map(itm => {
          if (itm.group === 4) {
            data.push(itm)
          }
        })
        return data
      },
      getHealthHistory () {
        return get(this.$store.state, 'formRegist.health_history_data', [])
      },
      getSkillData () {
        const skillData = get(this.$store.state, 'formRegist.skill_data', [])
        return this.getData(skillData)
      },
      getEducationData () {
        const educationData = get(this.$store.state, 'formRegist.education_history_data', [])
        return this.getData(educationData)
      },
      getJobData () {
        const jobData = get(this.$store.state, 'formRegist.job_history_data', [])
        return this.getData(jobData)
      },
      getServiceData () {
        const serviceData = get(this.$store.state, 'formRegist.service_history_data', [])
        return this.getData(serviceData)
      },
      getChurchData () {
        const churchData = get(this.$store.state, 'formRegist.church_member_data', [])
        return churchData
      },
      getFinancialData () {
        const financialData = get(this.$store.state, 'formRegist.financial_data', [])
        return financialData
      },
      getSpiritualData () {
        const spiritualData = get(this.$store.state, 'formRegist.personal_spiritual_data', [])
        return spiritualData
      },
      getPergumulanData () {
        const pergumulanData = get(this.$store.state, 'formRegist.personal_struggle_data', [])
        return pergumulanData
      },
      getOtherData () {
        const otherData = get(this.$store.state, 'formRegist.other_data', [])
        return this.getData(otherData)
      },
      getAgreement () {
        const agreementData = get(this.$store.state, 'formRegist.agreement_data', [])
        return agreementData
      },
      concentrationList () {
        const list = get(this.$store.state, 'concentrationList', [])
        let concentration = []
        list.map(item => {
          if (item.category.indexOf('mdiv') !== -1) {
            concentration.push(item.value)
          }
        })
        return concentration
      }
    },
    created () {
      this.$store.dispatch('getFormMdiv')
      const storeConcentration = get(this.$store.state, 'formRegist.concentration', '')
      this.concentration = storeConcentration ? storeConcentration : this.concentrationList[0]
      if (this.registrationStatus !== 'init') {
        this.agreementCheckbox = true
      }
    },
    mounted () {
      extend('required', required)
    },
    methods: {
      getData (item) {
        const data = []
        item.map(itm => {
          if (!data[itm.group-1]) {
            const list = []
            list.push(itm)
            data.push({list})
          } else {
            data[itm.group-1].list.push(itm)
          }
        })
        return data
      },
      isMarried (itm, idx) {
        return itm[0].order === 1 && itm[0].isShow
      },
      isMarriedDataShow (itm, idx) {
        const isShow = itm[0].order >= 2 && itm[0].isShow
        if (isShow) {
          this.isShowDivorced = true
        }
        return isShow
      },
      educationTitle (itm) {
        let title = ''
        if (itm.attr === 'sd') {
          title = 'SD'
        } else if (itm.attr === 'smp') {
          title = 'SMP'
        } else if (itm.attr === 'sma') {
          title = 'SMA'
        }
        return title
      },
      separateColoum (list = []) {
        const median = Math.ceil(list.length/2)
        return [{
          list: list.slice(0, median)
        }, {
          list: list.slice(median, 10)
        }]
      },
      handleStep (id) {
        this.activeStep = id
      },
      handleMultiForm (type, itm) {
        if (type === 'add') {
          this.$store.dispatch('addForm', itm)
        } else {
          this.$store.dispatch('removeForm', itm)
        }
      },
      submitForm () {
        this.$refs.form.validate().then(success => {
          if (!success) {
            const errors = this.$refs.form.errors
            this.activeStep = validateForm(formValidateSthMdiv, errors)
            this.$refs.dialogInfoInvalid && this.$refs.dialogInfoInvalid.show()
          } else {
            // success
            this.$refs.dialogConfirmSubmitForm && this.$refs.dialogConfirmSubmitForm.show()
          }
        })
      },
      handleOnSubmit (isDraft = false) {
        loadscreen.show()
        const formMdiv = this.$store.state.formRegist
        const academicYearList = this.isDashboard ? this.$store.state.dashboard.dashAcademicYear : this.$store.state.academicYear
        const academicYear = this.$store.state.selectedAcademicYear
        let academicYearId = ''
        academicYearList.map(itm => {
          if (itm.title === academicYear) {
            academicYearId = itm.id
          }
        })
        let params = processForm(formMdiv, {
          service_history_data: {
            list: []
          }
        })
        params.other_data.heard_from_where = checkHeardFrom(params.other_data.heard_from_where)
        const reqParams = {
          entry_form_id: this.formId,
          academic_year_id: academicYearId,
          concentration: this.concentration,
          personal_data: params.personal_data,
          family_data: params.family_data,
          health_history_data: params.health_history_data,
          education_history_data: [...params.education_history_data.list],
          church_member_data: params.church_member_data,
          financial_data: params.financial_data,
          personal_spiritual_data: params.personal_spiritual_data,
          personal_struggle_data: params.personal_struggle_data,
          other_data: params.other_data,
          agreement_data: params.agreement_data,
          marriage_data: params.marriage_data
        }
        if (params.skill_data.list[0].name) {
          reqParams.skill_data = [...params.skill_data.list]
        }
        if (params.service_history_data.list[0].church) {
          reqParams.service_history_data = [...params.service_history_data.list]
        }
        if (params.job_history_data.list[0].place) {
          reqParams.job_history_data = [...params.job_history_data.list]
        }
        if (!params.marriage_data.is_have_child) {
          reqParams.marriage_data.family_core_member = []
        }
        let apiUrl = API_FORM_REGISTRATION_FORM
        if (this.isDashboard) {
          apiUrl = API_DASH_REGISTRATION_FORM
        }

        if (isDraft) {
          reqParams.is_save_to_draft = true
        }
        
        request(apiUrl, reqParams).then(res => {
          if (res.success) {
            this.$refs.dialogInfoSuccess && this.$refs.dialogInfoSuccess.show()
          } else {
            this.alertMsg = get(res, 'resultInfo.resultMsg', '')
            this.isShowAlert = true
          }
          loadscreen.hide()
        })
      },
      handleBack () {
        this.$emit('handleBack', true)
      },
      handleInput (form) {
        if (form.fields === 'is_marriage') {
          this.isShowDivorced = form.value === 'Ya' ? true : false
        }
        this.$store.dispatch('setForm', form)
      }
    }
  }
</script>

<style lang="scss" scoped>
.stepper-form-mdiv {
  &-title {
    font-weight: bold;
    font-size: 14px;
    margin: 0;
    color: black;
  }
  &-body {
    font-size: 14px;
    margin: 0;
    color: black;
  }
}

</style>